import { useState, useCallback, useEffect, useMemo } from "react";
import { useDevice, useLocation } from "@datwyler/shared-components";
import { transformSitesFilter } from "../utils/index";

const useGatewayList = (tenantId) => {
  //pagination
  const [tenantID, setTenantID] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({ sites: [] });
  const [sort, setSort] = useState("name");

  const {
    fetchDevices,
    fetchDeviceData: devices,
    isFetchLoading: devicesLoading,
  } = useDevice();
  const { fetchLocationData: locations, fetchLocations } = useLocation();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId]);

  const getLocations = useCallback(() => {
    fetchLocations({
      variables: { tenantId: tenantID },
    });
  }, [tenantID, fetchLocations]);

  const getDeviceList = useCallback(() => {
    fetchDevices({
      variables: {
        tenantId: tenantID,
        filter: [
          ...transformSitesFilter(filters.sites),
          "type:GATEWAY",
          "status:ACTIVE",
        ],
        sort: sort,
        page: { number: page, size: rowsPerPage },
      },
    });
  }, [tenantID, sort, page, rowsPerPage, fetchDevices, filters]);

  useEffect(() => {
    if (tenantID) {
      getLocations();
      getDeviceList();
    }
  }, [tenantID, page, rowsPerPage, filters, sort, getLocations, getDeviceList]);

  return {
    devices: devices?.devices,
    devicesLoading: devicesLoading,
    locations: locations?.locations || [],
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    totalRows: devices?.devices?.page?.totalElements || 0,
    filters,
    setFilters,
    sort,
    setSort,
  };
};

export default useGatewayList;
