import { useState, useEffect, useCallback } from "react";
import { useDevice, useTelemetry } from "@datwyler/shared-components";
const useGatewayInformation = (tenantId, gatewayId) => {
  const [gatewayID, setGatewayID] = useState(gatewayId);
  const [tenantID, setTenantID] = useState();

  const { fetchDeviceById, fetchDeviceByIdData, isFetchDeviceByIdLoading } =
    useDevice();
  const { fetchTelemetryData, fetchTelemetry, isFetchTelemetryLoading } =
    useTelemetry();

  useEffect(() => {
    setGatewayID(gatewayId);
  }, [gatewayId]);

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId]);

  useEffect(() => {
    if (gatewayID) {
      fetchDeviceById({
        variables: { tenantId: tenantId, id: gatewayID },
      });
    }
  }, [fetchDeviceById, gatewayID]);

  useEffect(() => {
    if (tenantID && gatewayID) {
      const now = new Date();
      const startTime = Math.floor(now.setHours(now.getHours() - 1) / 1000); // past 1 hour
      fetchTelemetry({
        variables: {
          tenantId: tenantID,
          deviceIds: [gatewayID],
          startTime: startTime,
          page: { number: 0, size: 999999 }, // set a huge page size
          sort: ["time,asc"],
          filter: ["name:modem_temperature|name:modem_signal"],
        },
      });
    }
  }, [fetchTelemetry, tenantID, gatewayID]);

  return {
    deviceData: fetchDeviceByIdData?.deviceById,
    isDeviceDataLoading: isFetchDeviceByIdLoading,
    telemetryData: fetchTelemetryData?.telemetries?.telemetries || [],
    isTelemetryDataLoading: isFetchTelemetryLoading,
  };
};

export default useGatewayInformation;
